import gql from 'graphql-tag';

export default gql`
query GetAllAvailableLanguages {
  languages {
    language_id
    name
    handle
  }
}
`;
