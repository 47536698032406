import gql from 'graphql-tag';

export default gql`
query getAllPluginDataOfProject($projectId: Int) {
  plugins(where: {project_id: {_eq: $projectId}}) {
    enabled
    handle
    settings
  }
}
`;
