import gql from 'graphql-tag';

export default gql`
mutation insertPlugin(
  $object: [plugins_insert_input!]!
) {
  insert_plugins(objects: $object) {
    affected_rows
  }
}
`;
