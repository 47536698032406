import gql from 'graphql-tag';

export default gql`
mutation updateSearchableOnPagesByIds(
  $pageIds: [Int!],
  $isSearchable: Boolean
) {
  update_pages(where: {page_id: {_in: $pageIds}}, _set: {searchable: $isSearchable}) {
    affected_rows
  }
}
`;
