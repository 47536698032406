import gql from 'graphql-tag';

export default gql`
query getProjectWithChildPagesDataToPublish($projectId: Int!) {
   projects_by_pk(project_id: $projectId) {
     project_id
     canonical_url
     hreflang
     og_description
     og_images
     og_title
     slug
     name
     corporate_identity
     description
     keywords
     language_id
     published
     published_at
     searchable
     theme_id
     theme_settings
     thumbnails
     type_id
     listed
     plugins {
       handle
       enabled
       settings
     }
     child_pages {
       canonical_url
       og_images
       og_title
       og_description
       slug
       order_index
       page_id
       listed
       published
       description
       name
       language_id
       keywords
       searchable
       thumbnails
       latest_page_version {
         page_version_id
         display_data
         version_number
       }
       template {
         name
       }
       pages_tags {
         tag {
           name
         }
       }
     }
     theme {
       name
       updated_at
     }
     template_collection {
        name
     }
     group {
       domain
     }
   }
 }
 `;
