import gql from 'graphql-tag';

export default gql`
query getAllChildSlugsOfProject($projectId: Int!) {
  pages(where: {parent_project_id: {_eq: $projectId}}, order_by: {order_index: asc}) {
    page_id
    slug
  }
}
`;
