import gql from 'graphql-tag';

export default gql`
query getProjectSettingsById($id: Int!) {
  projects_by_pk(project_id: $id) {
    project_id
    parent_folder_id
    name
    description
    listed
    searchable
    listed
    published
    language_id
    corporate_identity
    is_shared_template
    theme_id
    theme_settings
    thumbnails
    custom_css
    projects_tags {
      tag {
        name
      }
    }
    og_title
    slug
    hreflang
  }
}
`;
