import gql from 'graphql-tag';

export default gql`
mutation SetPassword($pluginData: SetPasswordInput!) {
  setPassword(object: $pluginData) {
    plugin_id
    plugin {
      folder_id
      project_id
      handle
      settings
    }
  }
}
`;
