import gql from 'graphql-tag';

export default gql`
mutation setProjectSettings(
  $projectObject: projects_set_input,
  $projectId: Int
) {
  update_projects(
    where: {
      project_id: {_eq: $projectId}
    } 
    _set: $projectObject
  ) {
    affected_rows
  }
}
`;
